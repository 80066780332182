@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

button {
    background-color: #ffbd59;
    width: fit-content;
    height: auto;
    color: #ffffff;
    border: none;
    border-radius: 5em;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 1em;
    cursor: pointer;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none;
}