header {
    max-width: 100%;
    height: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5em 5em;
    background: linear-gradient(to left, #00687d, #6ca4af);
}

.header-text {
    background-image: url('../img/header-text.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50%;
}
