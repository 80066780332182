.about-us-text-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
}

.about-us-text {
    width: 65%;
}

.about-us-img {
    width: 30%;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .about-us-text-content {
        flex-direction: column-reverse;
        align-items: center;
    }

    .about-us-text {
        width: 100%;
    }

    .about-us-img {
        width: 50%;
        height: auto;
    }
}