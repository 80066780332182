.front-content {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    width: 100%;
}

.front-content-who-is-valomax {
    max-width: 20%;
}

.front-content-who-is-valomax img {
    width: 100%;
}

.front-content-services {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    max-width: 70%;
}

.front-content-services-img {
    width: 56%;
    background-image: url('../img/services.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 2em;
}

.front-content-services-text {
    width: 40%;
    text-align: justify;
}

@media only screen and (max-width: 768px) {
    .front-content-services {
        flex-wrap: wrap;
    }

    .front-content-services-img {
        min-height: 15em;
    }
}