.columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 65%;
    flex-wrap: wrap;
}

.contact-item {
    display: flex;
    align-items: center;
    margin: 0.5em;
}

.contact-item img {
    max-width: 1.8em;
    max-height: 1.8em;
    margin-right: 1em;
}