@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400;1,500&display=swap');

main {
  width: 100%;
  min-height: 100vh;
  font-family: 'Montserrat';
  color: #545454;
}

.main-body-section {
  max-width: 100%;
  padding: 0 7em;
}

.content {
  margin: 2em 0;
}

p {
  font-weight: 400;
  text-align: justify;
  font-size: 1.1rem;
}

hr {
  border: 0.5px solid #b8b9bb;
  margin: 2em 0;
}

h1 {
  font-size: 2.5em;
  font-weight: 500;
}

h2 {
  font-weight: 400;
  text-align: left;
}

h3 {
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .main-body-section {
    padding: 0 1em;
  }

  .front-content {
    flex-direction: column;
    align-items: center;
  }

  .front-content-who-is-valomax,
  .front-content-services {
    max-width: 100%;
  }

  .front-content-services-img,
  .front-content-services-text {
    width: 100%;
  }

  .partnership-img {
    width: 100%;
    margin-left: 0;
    border-radius: 0;
  }

  .right-content,
  .left-content {
    flex-direction: column;
    text-align: center;
  }

  .text-content-right,
  .text-content-left {
    margin: 0;
  }
}
