footer {
    max-width: 100%;
    height: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0.5em 5em;
    background: linear-gradient(to left, #00687d, #6ca4af);
    margin-top: 2em;
}

footer p {
    color: #ffff;
    font-size: 1em;
}

@media only screen and (max-width: 768px) {
    footer {
        height: 6em;
        font-size: 0.8em;
    }
}