.right-content {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1.5em 0;
}
  
.text-content-right {
  margin-right: 2em;
}