.front-partnership {
    display: flex;
    flex-direction: row;
}
  
.front-partnership-text h2 {
    font-size: 3em;
    font-weight: 500;
    margin-top: 0;
}

.partnership-img {
    width: 37em;
    height: auto;
    margin-left: 3em;
    border-radius: 10%;
    background-image: url('../img/partnership-img.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
    .partnership-img {
        display: none;
    }
}