.left-content {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1.5em 0;
}

.text-content-left {
    margin-left: 2em;
}